<template>
  <div
    class="mx-auto w-full h-full"
    :class="{'max-w-md':compact}"
    @reset-form="resetForm"
  >
    <div class="h-full flex flex-col">
      <atoms-alert
        v-if="newAccountWarning"
        size="p1"
        type="warning"
        has-close
        :full-width="true"
        class="mb-6"
      >
        <strong>Hmm, we couldn't find an account with that email.</strong><br />
        Double-check your email and
        <span
          class="text-dark underline font-bold cursor-pointer"
          @click="resetForm"
        >try again</span> or
        register a new account.
      </atoms-alert>
      <h1 class="text-dark text-xl">
        <strong class="font-bold">Club<sup class="text-pink-600">+</sup></strong>
      </h1>
      <div class="py-2 mb-4 text-sm">
        Savings, discounts and more!
      </div>
      <template v-if="!isRegistrationAttempt">
        <AtomsButtonSSOTile
          v-for="SSO in activeSSOItems"
          :key="SSO"
          :sso-btn-pre-text="ssoBtnPreText"
          :sso-type="SSO"
          full-width
          class="mb-4"
          @click="socialAuthInit({ social_auth_type: SSO })"
        />
      </template>
      <p
        v-if="!isRegistrationAttempt"
        class="w-full text-center text-sm border-b border-solid border-gray-400 leading-[0.1em] mt-2.5 mb-5"
      >
        <span class="bg-white px-12 text-dark font-normal">
          OR
        </span>
      </p>
      <organisms-form-generator
        class="flex sm:h-auto h-full flex-col"
        :enable-tracking="isRegistrationAttempt"
        :schema="schema"
        :model="model"
        :buttons="formButtons"
        :submit-btn-txt="submitBtnTxt"
        :button-grid="buttonGrid"
        @submit="authStepsHandler"
        @back="resetForm"
      >
        <template
          v-if="isLoginAttempt"
          #form-footer
        >
          <div class="text-right -mt-2 mb-4">
            <nuxt-link
              class="text-dark underline text-xs"
              to="/forgot-password/"
            >
              <strong class="font-bold">Forgot your password?</strong>
            </nuxt-link>
          </div>
        </template>
      </organisms-form-generator>
      <p class="m-4 text-sm font-normal text-center text-dark">
        <template v-if="authRoute === 'login' && !hideAuthFooterLink">
          Not a member?
          <atoms-button
            id="auth_register_btn"
            class="p-0 !text-base !inline-flex"
            size="small"
            theme="tertiary"
            enable-tracking
            @click="redirectionHandler($event, '/register/')"
          >
            Join the Club
          </atoms-button>
        </template>
        <template v-if="authRoute === 'register' && !hideAuthFooterLink">
          Already a member?
          <atoms-button
            id="auth_login_btn"
            class="p-0 !text-base !inline-flex"
            size="small"
            theme="tertiary"
            enable-tracking
            @click="redirectionHandler($event, '/login/')"
          >
            Log in
          </atoms-button>
        </template>
      </p>
      <div
        v-if="isRegistrationAttempt"
        class="font-normal text-gray-80 text-xs text-center px-0 pb-6 leading-[1.125rem]"
      >
        By clicking 'JOIN THE CLUB', I acknowledge that I have read and agree to the
        <nuxt-link
          ref="terms_of_use"
          class="underline cursor-pointer"
          to="/terms-of-use/"
          target="_blank"
        >
          <strong>Terms of Use</strong>
        </nuxt-link>, the
        <nuxt-link
          ref="privacy_policy"
          to="/privacy-policy/"
          target="_blank"
          class="underline cursor-pointer"
        >
          <strong>Privacy Policy</strong>
        </nuxt-link> and the
        <nuxt-link
          ref="collection_notice"
          to="/collection-notice/"
          target="_blank"
          class="underline cursor-pointer"
        >
          <strong>Collection Notice</strong>
        </nuxt-link>.
      </div>
    </div>
  </div>
</template>

<script setup>
import memberhubConstants from '~/constants/memberhub.constants'

defineOptions({
  name: 'OrganismsAuthentication'
})
const { $tracking, $bus } = useNuxtApp()
const route = useRoute()

const userStore = useUserStore()
const props = defineProps({
  authRoute: {
    type: String,
    required: true,
    validator (val) {
      return ['login', 'register'].includes(val)
    }
  },
  compact: {
    type: Boolean,
    default: true
  }
})

const emits = defineEmits(['reset-form', 'user-validation','ps-registration'])

const newAccountWarning = ref(false)
const hideAuthFooterLink = ref(false)
const schema = ref({
  email: {
    type: 'text',
    theme: 'default',
    label: 'Email',
    fieldLabel: '<strong>Email</strong>',
    validate: 'email'
  }
})
const model = ref({
  email: null
})
const isLoginAttempt = ref(false)
const isRegistrationAttempt = ref(false)
const registrationSchema = ref({
  given_name: {
    type: 'text',
    label: 'First name',
    theme: 'default',
    fieldLabel: '<strong>First name</strong>',
    validate: 'text'
  },
  family_name: {
    type: 'text',
    label: 'Last name',
    theme: 'default',
    fieldLabel: '<strong>Last name</strong>',
    validate: 'text'
  },
  password: {
    type: 'password',
    label: 'Password',
    theme: 'default',
    fieldLabel: '<strong>Password</strong>',
    validate: 'password',
    passwordMeter: true
  }
})
const registrationModel = ref({
  given_name: null,
  family_name: null,
  password: null
})
const formButtons = ref([
  {
    type: 'submit',
    action: 'submit',
    size: 'medium',
    label: 'JOIN THE CLUB'
  }
])
const buttonGrid = ref('grid-cols-1 mt-auto')
const accountSetupButtons = ref([
  {
    type: 'button',
    action: 'back',
    theme: 'default',
    size: 'medium',
    label: 'BACK',
    component: resolveComponent('AtomsButtonBack')
  },
  {
    type: 'submit',
    action: 'submit',
    size: 'medium',
    class: 'whitespace-nowrap',
    label: 'JOIN THE CLUB'
  }
])

const isLoginPage = computed(() => {
  return props.authRoute === 'login'
})
const submitBtnTxt = computed(() => {
  if (isLoginAttempt.value) {
    return 'Log In'
  }
  if (isRegistrationAttempt.value) {
    return 'Join The Club'
  }
  return 'Continue'
})

const activeSSOItems = computed(() => {
  return [memberhubConstants.GOOGLE_AUTH, memberhubConstants.FACEBOOK_AUTH]
})

const ssoBtnPreText = computed(() => {
  return isLoginPage.value ? 'Continue' : 'Join'
})

const modalAuth = computed(() => {
  return route.name === 'health-insurance-quotes-results'
})

/*
 * Email prefill handler
 * This method prefills an email coming from route query
 */
function prefillEmail () {
  const tmpEmail = route.query.email
  if (tmpEmail) {
    model.value.email = tmpEmail
  }
}
const vaultStore = useVaultStore()

/*
 * Handles the authentication steps based on the user's current action.
 * This function determines whether the user is attempting to log in, register, or verify purchase status.
 * For login and registration attempts, it calls the respective methods in the `userStore` and emits an 'authenticated' event upon success.
 * If neither login nor registration is being attempted, it checks if the user has previously made a purchase using their email.
 * If the purchase is verified, it emits a 'ps-registration' event with the user's name and email.
 * If the user's purchase cannot be verified or an error occurs, it calls `userExistsHandler` to handle the next steps.
 *
 * @param {Object} payload - The data payload for the authentication step, which varies based on the action (login, registration, etc.).
 */
function authStepsHandler (payload) {
  if (isLoginAttempt.value) {
    userStore.login(payload)
      .then(() => {
        $bus.emit('authenticated')
      })
  } else if (isRegistrationAttempt.value) {
    userStore.register(payload)
      .then(() => {
        validatedUserRedirectionHandler()
      })
  } else {
    vaultStore.checkPurchasedUser(model.value.email)
      .then(res => {
        if(res.is_valid) {
          emits('ps-registration', { name:res.given_name,email:model.value.email })
        } else {
          userExistsHandler(model.value.email)
        }
      })
      .catch(() => {
        userExistsHandler(model.value.email)
      })
  }
}
/*
 * Handles the existence check of a user by their email.
 * This function calls `userStore.checkUserExistence` with the provided email to check if the user exists.
 * Upon receiving a response, it forwards the result to `authTypesHandler` to determine the next steps based on the user's existence.
 * If an error occurs during the check, it calls `authTypesHandler` without any arguments, indicating a fallback or error state.
 *
 * @param {string} email - The email address to check for an existing user.
 */
function userExistsHandler (email) {
  userStore.checkUserExistence(email)
    .then(res => {
      authTypesHandler(res)
    })
    .catch(() => {
      authTypesHandler()
    })
}

/*
 * Authentication type handler with respect to email check response
 * This method checks the auth type and handles / passes user to desired authentication
 * @param authType
 */
function authTypesHandler (authType) {
  switch (authType) {
    case 'password':
      hideAuthFooterLink.value = true
      isLoginAttempt.value = true
      isRegistrationAttempt.value = false
      schema.value.password = {
        type: 'password',
        theme: 'default',
        label: 'Password',
        fieldLabel: '<strong>Password</strong>',
        validate: 'text'
      }
      model.value.password = null
      break
    case 'google':
      socialAuthInit({ social_auth_type: memberhubConstants.GOOGLE_AUTH })
      break
    case 'facebook':
      socialAuthInit({ social_auth_type: memberhubConstants.FACEBOOK_AUTH })
      break
    default:
      hideAuthFooterLink.value = true
      newAccountWarning.value = route.name === 'login'
      isLoginAttempt.value = false
      isRegistrationAttempt.value = true
      schema.value = { ...schema.value, ...registrationSchema.value }
      model.value = { ...model.value, ...registrationModel.value }
      buttonGrid.value = 'grid-cols-2'
      formButtons.value = accountSetupButtons.value
      break
  }
  nextTick().then(() => {
    prefillEmail()
  })
}

/*
 * Form reset handler
 */
function resetForm () {
  const email = model.value.email
  nextTick().then(() => {
    model.value.email = email
    hideAuthFooterLink.value = !!modalAuth.value

    isLoginAttempt.value = false
    isRegistrationAttempt.value = false
    schema.value = {
      email: {
        type: 'text',
        theme: 'default',
        label: 'Email',
        fieldLabel: '<strong>Email</strong>',
        validate: 'email'
      }
    }
    model.value = {
      email: null
    }
    buttonGrid.value = 'grid-cols-1 mt-auto'
    formButtons.value = [{
      type: 'submit',
      action: 'submit',
      size: 'medium',
      label: 'JOIN THE CLUB'
    }]

  })
}

function redirectionHandler (e, path) {
  if (route.name === 'health-insurance-quotes-results') {
    resetForm()
  } else {
    $tracking.click(e, path)
    $bus.emit('redirection', { path })
  }
}

/*
 * SSO initialize
 * @param payload
 */
function socialAuthInit (payload) {
  userStore.socialAuthInit(payload)
}

/*
 * Redirection handler for button action in validated user case
 * @param e
 */
function validatedUserRedirectionHandler () {
  const isOnboarding = !userStore.getUserProfile?.profile?.onboardingCompleted
  const defaultPage = isOnboarding ? '/dashboard/onboarding/' : '/dashboard/'
  const path = modalAuth.value ? (userStore.getAuthUserSourcePage || defaultPage) : defaultPage
  const query = modalAuth.value ? (userStore.getAuthUserSourcePageQuery || {}) : {}
  $bus.emit('authenticated')
  $bus.emit('redirection', { path, query })
}

onMounted(() => {
  nextTick().then(() => {
    prefillEmail()
    if (route.name === 'health-insurance-quotes-results') {
      hideAuthFooterLink.value = true
    }
  })
})

</script>

<style scoped>

</style>
